export function formatDate(date: Date) {
  const month = ("" + (date.getMonth() + 1)).padStart(2, "0");
  const day = ("" + date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function firstDayOfMonth() {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
}
