import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useLogin, useSafeSetState, useTranslate } from "ra-core";
import * as React from "react";
import { FunctionComponent } from "react";
import { Field, Form } from "react-final-form";
import logo from "../images/ummaty-logo.png";

interface Props {
  redirectTo?: string;
}

interface FormData {
  username: string;
  password: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "#055064",
    },
    logo: {
      marginTop: "4em",
      height: 200,
    },
    card: {
      minWidth: 300,
      marginTop: "1em",
    },
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      backgroundColor: "#055064",
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    error: {
      color: "red",
      padding: "10px",
      textAlign: "center",
    },
  }),
  { name: "RaLoginForm" }
);

const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

export const LoginPage: FunctionComponent<Props> = (props: Props) => {
  const { redirectTo } = props;
  const [withPassword, setWithPassword] = useSafeSetState<boolean>(false);
  const [loading, setLoading] = useSafeSetState<boolean>(false);
  const [error, setError] = useSafeSetState<string>("");
  const login = useLogin();
  const translate = useTranslate();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors: any = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (withPassword && !values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const submit = async ({
    username,
    password,
  }: {
    username?: string;
    password?: string;
  }) => {
    setLoading(true);
    setError("");
    try {
      await login({ email: username, password }, redirectTo);
    } catch (error) {
      if (error) {
        setError(error);
      } else {
        setWithPassword(true);
      }
      setLoading(false);
    }
  };

  return (
    <div className={classes.main}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <Card className={classes.card}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="username"
                    name="username"
                    component={Input}
                    label={translate("ra.auth.username")}
                    disabled={loading}
                  />
                </div>
                {withPassword && (
                  <div className={classes.input}>
                    <Field
                      id="password"
                      name="password"
                      component={Input}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                      autoComplete="current-password"
                    />
                  </div>
                )}
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                >
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
            </form>
          )}
        />
        {error && <div className={classes.error}> {error} </div>}
      </Card>
    </div>
  );
};
