import { Select, SelectList } from "./Select";
import * as React from "react";
import { ReactElement } from "react";
import DONATION_TYPES from "../core/data/donation-types.json";

const projects: SelectList = DONATION_TYPES.map((type) => ({
  value: type,
  label: type,
}));

export interface SelectProjectProps {
  onChange: (project: string) => void;
  project: string;
}

export function SelectProject({
  project,
  onChange,
}: SelectProjectProps): ReactElement {
  return (
    <Select
      label="Countries"
      value={project}
      onChange={onChange}
      list={projects}
    />
  );
}
