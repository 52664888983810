import {
  getSubscriptions,
  SubscriptionsResponse,
  SubscriptionStatus,
} from "../api";
import { useQuery } from "react-query";

export function useSubscriptions(
  status: SubscriptionStatus,
  fromDate?: string,
  toDate?: string
) {
  return useQuery<SubscriptionsResponse, Error>("repoData", () => {
    return getSubscriptions(status, fromDate, toDate);
  });
}
