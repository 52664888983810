import MenuItem from "@material-ui/core/MenuItem";
import MaterialSelect from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

export interface SelectProps {
  onChange: (country: string) => void;
  list: SelectList;
  label: string;
  value: string;
}

export type SelectList = { value: string; label: string }[];

export function Select({
  value,
  list,
  label,
  onChange,
}: SelectProps): ReactElement {
  const classes = useStyles();
  const allValue = value || "all";
  return (
    <MaterialSelect
      label={label}
      value={allValue}
      className={classes.select}
      onChange={(e) => {
        const value = e.target.value as string;
        onChange(value === "all" ? "" : value);
      }}
    >
      <MenuItem value={"all"}>All</MenuItem>
      {list.map(({ value, label }, index) => (
        <MenuItem key={index} value={value}>{label}</MenuItem>
      ))}
    </MaterialSelect>
  );
}
