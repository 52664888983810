export async function requestLogin(email: string): Promise<void> {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/request_login`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ email }),
    }
  );
  if (response.status === 400) {
    const { error } = await response.json();
    throw error.email;
  }
}

export async function login(email: string, password: string): Promise<string> {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ email, key: password }),
  });
  if (response.status === 400) {
    const { error } = await response.json();
    throw error;
  }
  const { jwt } = await response.json();
  return jwt;
}
