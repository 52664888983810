import { authProvider } from "../auth/authProvider";
import { Donation, Metadata, Stat } from "../models";

export interface DonationsResponse<T extends Metadata = Metadata> {
  donations: Donation<T>[];
  stats: Stat[];
}

export enum DonationType {
  BYOW = "Build Your Own Well",
  WFA = "Water For All",
  GD = "General Donation",
  ANSAR = "Ansar",
  ZAKAT = "Zakat",
  FITR = "Zakat Al Fitr",
  Udhiya = "Udhiya",
  W4A = "Water For All",
  RAMADAN = "Ramadan Basket",
  IFTAR = "Ramadan Iftar",
  FEEDING = "Feeding Hope",
  BU = "Bany Ummaty",
  ATLAS = "Atlas Relief",
}

export enum DonationGroup {
  PROJECT = "charity_organization",
  COUNTRY = "metadata.user_country",
  WELL_TYPE = "charity_organization,metadata.country,metadata.well_pump",
}

const apiUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/donations`;

export async function getDonations<T extends Metadata>(
  type?: DonationType,
  group?: DonationGroup,
  fromDate?: string,
  toDate?: string,
  search: { [key: string]: string } = {}
): Promise<DonationsResponse<T>> {
  const url = new URL(apiUrl);
  if (fromDate) {
    url.searchParams.append("from_date", fromDate);
  }
  if (toDate) {
    url.searchParams.append("to_date", toDate);
  }
  if (type) {
    url.searchParams.append("donation_type", type);
  }
  if (group) {
    url.searchParams.append("group_by", group);
  }
  Object.keys(search).forEach((key) => {
    if (search[key]) url.searchParams.append(key, search[key]);
  });
  return await authProvider.fetch(url);
}
