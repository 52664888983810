import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import { ReactElement } from "react";
import WELL_PUMPS from "../core/data/well-pumps.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

export interface SelectWellTypeProps {
  onChange: (wellType: string) => void;
  wellType: string;
}

export function SelectWellType({
  wellType,
  onChange,
}: SelectWellTypeProps): ReactElement {
  const classes = useStyles();
  const wellTypeValue = wellType || "all";
  return (
    <Select
      labelId="format-label"
      label="WellType"
      id="format"
      value={wellTypeValue}
      className={classes.select}
      onChange={(e) => {
        const value = e.target.value as string;
        onChange(value === "all" ? "" : value);
      }}
    >
      <MenuItem value="all">All</MenuItem>
      {Object.entries(WELL_PUMPS).map(([key, value], index) => (
        <MenuItem key={index} value={key}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}
