import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Donation } from "../core";
import { useTotals } from "../hooks/useTotals";

const useStyles = makeStyles({
  head: {
    fontWeight: "bold",
    textAlign: "right",
  },
  number: {
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
  ul: {
    listStyle: "none",
    padding: 0,
  },
});

export function StatsDonations({ donations }: { donations: Donation[] }) {
  const classes = useStyles();
  const { totalPaypal, totalStripe } = useTotals(donations);
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Donator</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>User Country</TableCell>
              <TableCell>Metadata</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Recurrent</TableCell>
              <TableCell className={classes.head}>Paypal</TableCell>
              <TableCell className={classes.head}>Stripe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map((donation: Donation, index: number) => (
              <TableRow key={index}>
                <TableCell>
                  {donation.first_name} {donation.last_name}
                </TableCell>
                <TableCell>
                  {new Date(donation.created_at).toLocaleDateString()}&nbsp;
                  {new Date(donation.created_at).toLocaleTimeString()}
                </TableCell>
                <TableCell>{donation.charity_organization}</TableCell>
                <TableCell>{donation.metadata?.user_country}</TableCell>
                <TableCell>
                  <ul className={classes.ul}>
                    {donation.metadata?.country && (
                      <li>Country: {donation.metadata?.country}</li>
                    )}
                    {donation.metadata?.name && (
                      <li>Name: {donation.metadata?.name}</li>
                    )}
                    {donation.metadata?.quantity && (
                      <li>Quantity: {donation.metadata?.quantity}</li>
                    )}
                    {donation.metadata?.language && (
                      <li>Language: {donation.metadata?.language}</li>
                    )}
                  </ul>
                </TableCell>
                <TableCell>{donation.email}</TableCell>
                <TableCell>{donation.monthly && "X"}</TableCell>
                <TableCell className={classes.number}>
                  {donation.payment_gateway === "PAYPAL" ? donation.amount : 0}
                </TableCell>
                <TableCell className={classes.number}>
                  {donation.payment_gateway === "STRIPE" ? donation.amount : 0}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={classes.number}>{totalPaypal}</TableCell>
              <TableCell className={classes.number}>{totalStripe}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
