import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import { Title } from "react-admin";
import { useQuery } from "react-query";
import { DateRange, useDateRange } from "../components/DateRange";
// import { SelectCountry } from "../components/SelectCountry";
import { SelectProject } from "../components/SelectProject";
import { StatGroup } from "../components/StatGroup";
import { StatsDonations } from "../containers/StatsDonations";
import { StatsGlobal } from "../containers/StatsGlobal";
import {
  DonationGroup,
  DonationsResponse,
  getDonations,
} from "../core/api/donations";
import { firstDayOfMonth } from "../helpers/Date";

const firstDay = firstDayOfMonth();
const today = new Date();

export function Donations() {
  // const [country, setCountry] = React.useState<string>("");
  const [project, setProject] = React.useState<string>("");
  const [group, setGroup] = React.useState<DonationGroup>(
    DonationGroup.COUNTRY
  );
  const { fromDate, toDate, onDateRageChange } = useDateRange(firstDay, today);

  const { data } = useQuery<DonationsResponse, Error>(
    ["donations", { type: undefined, group, fromDate, toDate, project }],
    (key, { type, group, fromDate, toDate, project }) =>
      getDonations(type, group, fromDate, toDate, { donation_type: project })
  );

  return (
    <div>
      <Title title="Donations" />
      <FormGroup row>
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={onDateRageChange}
        />
        {/* <SelectCountry country={country} onChange={setCountry} /> */}
        <SelectProject project={project} onChange={setProject} />
      </FormGroup>
      <h1> Global Stats </h1>
      <StatGroup group={group} onChange={setGroup} groups={[DonationGroup.COUNTRY, DonationGroup.PROJECT]} />
      <StatsGlobal group={group} stats={data?.stats || []} />
      <h1> Detailed Stats </h1>
      <StatsDonations donations={data?.donations || []} />
    </div>
  );
}
