import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import { Title } from "react-admin";
import { useQuery } from "react-query";
import { DateRange, useDateRange } from "../components/DateRange";
import { SelectCountry } from "../components/SelectCountry";
import { SelectWellType } from "../components/SelectWellType";
import { StatGroup } from "../components/StatGroup";
import { StatsBuildYourOwnWell } from "../containers/StatsBuildYourOwnWell";
import { StatsGlobal } from "../containers/StatsGlobal";
import { BuildYourOwnWellMetaData } from "../core";
import {
  DonationGroup,
  DonationsResponse,
  DonationType,
  getDonations,
} from "../core/api/donations";
import { firstDayOfMonth } from "../helpers/Date";

const firstDay = firstDayOfMonth();
const today = new Date();

export function BuildYourOwnWell() {
  const [wellType, setWellType] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("");
  const [group, setGroup] = React.useState<DonationGroup>(
    DonationGroup.COUNTRY
  );
  const { fromDate, toDate, onDateRageChange } = useDateRange(firstDay, today);

  const { data } = useQuery<DonationsResponse<BuildYourOwnWellMetaData>, Error>(
    ["buildyourownwell", { group, fromDate, toDate, country, wellType }],
    (key, { group, fromDate, toDate, country, wellType }) =>
      getDonations<BuildYourOwnWellMetaData>(
        DonationType.BYOW,
        group,
        fromDate,
        toDate,
        { country, well_pump: wellType }
      )
  );

  return (
    <div>
      <Title title="Build Your Own Well" />
      <FormGroup row>
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={onDateRageChange}
        />
        <SelectCountry country={country} onChange={setCountry} />
        <SelectWellType wellType={wellType} onChange={setWellType} />
      </FormGroup>
      <h1> Global Stats </h1>
      <StatGroup
        group={group}
        onChange={setGroup}
        groups={[DonationGroup.COUNTRY, DonationGroup.WELL_TYPE]}
      />
      <StatsGlobal group={group} stats={data?.stats || []} />
      <h1> Detailed Stats </h1>
      <StatsBuildYourOwnWell donations={data?.donations || []} />
    </div>
  );
}
