import FormGroup from "@material-ui/core/FormGroup";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { ReactElement, useState } from "react";
import { formatDate } from "../helpers/Date";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

export interface DateRangeProps {
  onChange: (fromDate: Date, toDate: Date) => void;
  fromDate: Date;
  toDate: Date;
}

export function useDateRange(firstDay: Date, today: Date) {
  const [fromDate, setFromDate] = useState<Date>(firstDay);
  const [toDate, setToDate] = useState<Date>(today);
  const onDateRageChange = React.useCallback((fromDate: Date, toDate: Date) => {
    setFromDate(fromDate);
    setToDate(toDate);
  }, []);
  return { fromDate, toDate, onDateRageChange };
}

export function DateRange({
  onChange,
  fromDate,
  toDate,
}: DateRangeProps): ReactElement {
  const classes = useStyles();
  const [fromDateSync, setFromDate] = useState<Date>(fromDate);
  const [toDateSync, setToDate] = useState<Date>(toDate);

  React.useEffect(() => {
    onChange(fromDateSync, toDateSync);
  }, [onChange, fromDateSync, toDateSync]);

  return (
    <FormGroup row>
      <TextField
        id="fromDate"
        label="From"
        type="date"
        defaultValue={formatDate(fromDate)}
        onChange={(e: any) => {
          setFromDate(new Date(e.target.value));
        }}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="toDate"
        label="To"
        type="date"
        defaultValue={formatDate(toDate)}
        onChange={(e: any) => {
          setToDate(new Date(e.target.value));
        }}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormGroup>
  );
}
