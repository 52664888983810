import * as React from "react";
import { Transaction } from "../core/models/Transaction";

export function useTotals(donations: Transaction[]) {
  return React.useMemo(
    () =>
      donations.reduce(
        (totals, donation: Transaction) => {
          if (donation.payment_gateway === "STRIPE")
            totals.totalStripe += donation.amount;
          if (donation.payment_gateway === "PAYPAL")
            totals.totalPaypal += donation.amount;
          return totals;
        },
        {
          totalPaypal: 0,
          totalStripe: 0,
        }
      ),
    [donations]
  );
}
