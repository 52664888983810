import * as React from "react";
import { Route } from "react-router-dom";
import { Subscriptions } from "./pages/Subscriptions";
import { BuildYourOwnWell } from "./pages/BuildYourOwnWell";
import { Donations } from "./pages/Donations";

export default [
  <Route exact path="/subscriptions" component={Subscriptions} />,
  <Route exact path="/build-your-own-well" component={BuildYourOwnWell} />,
  <Route exact path="/donations" component={Donations} />,
];
