import { Select, SelectList } from "./Select";
import * as React from "react";
import { ReactElement } from "react";

const countries: SelectList = [
  { value: "cambodia", label: "Cambodia" },
  { value: "canada", label: "Canada" },
  { value: "cameroun", label: "Cameroun" },
  { value: "ghana", label: "Ghana" },
  { value: "nepal", label: "Nepal" },
  { value: "sri lanka", label: "Sri Lanka" },
];

export interface SelectCountryProps {
  onChange: (country: string) => void;
  country: string;
}

export function SelectCountry({
  country,
  onChange,
}: SelectCountryProps): ReactElement {
  return (
    <Select
      label="Countries"
      value={country}
      onChange={onChange}
      list={countries}
    />
  );
}
