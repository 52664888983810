import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink } from "react-admin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PaymentIcon from "@material-ui/icons/Payment";
import OpacityIcon from "@material-ui/icons/Opacity";
import MoneyIcon from "@material-ui/icons/Money";

const Menu = ({ onMenuClick, logout }: any) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  return (
    <>
      <MenuItemLink
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/subscriptions"
        primaryText="Recurrent Payments"
        leftIcon={<PaymentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/build-your-own-well"
        primaryText="Build your own well"
        leftIcon={<OpacityIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/donations"
        primaryText="All donations"
        leftIcon={<MoneyIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </>
  );
};

export default Menu;
