import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ReactElement } from "react";
import { DonationGroup } from "../core/api";

export interface FormatProps {
  groups: DonationGroup[];
  onChange: (group: DonationGroup) => void;
  group: DonationGroup;
}

export function StatGroup({
  group,
  onChange,
  groups,
}: FormatProps): ReactElement {
  return (
    <RadioGroup row>
      {groups.map((listGroup, index) => {
        if (listGroup === DonationGroup.COUNTRY) {
          return (
            <FormControlLabel
              key={index}
              checked={group === listGroup}
              control={<Radio color="primary" />}
              label="Country"
              labelPlacement="start"
              onChange={(e: any) => onChange(listGroup)}
            />
          );
        }
        if (listGroup === DonationGroup.PROJECT) {
          return (
            <FormControlLabel
              key={index}
              checked={group === listGroup}
              control={<Radio color="primary" />}
              label="Project"
              labelPlacement="start"
              onChange={(e: any) => onChange(listGroup)}
            />
          );
        }
        if (listGroup === DonationGroup.WELL_TYPE) {
          return (
            <FormControlLabel
              key={index}
              checked={group === listGroup}
              control={<Radio color="primary" />}
              label="Well Type"
              labelPlacement="start"
              onChange={(e: any) => onChange(listGroup)}
            />
          );
        }
        return <React.Fragment key={index}></React.Fragment>;
      })}
    </RadioGroup>
  );
}
