import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { Stat } from "../core";
import { DonationGroup } from "../core/api";
import WELL_PUMPS from "../core/data/well-pumps.json";

const useStyles = makeStyles({
  head: {
    fontWeight: "bold",
    textAlign: "right",
  },
  number: {
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
});

export function StatsGlobal({
  stats,
  group,
}: {
  stats: Stat[];
  group?: DonationGroup;
}) {
  const classes = useStyles();
  const { totalCount, totalPaypal, totalStripe } = stats.reduce(
    (totals, subscription) => {
      totals.totalPaypal += subscription.PAYPAL?.amount || 0;
      totals.totalStripe += subscription.STRIPE?.amount || 0;
      totals.totalCount +=
        (subscription.STRIPE?.count || 0) + (subscription.PAYPAL?.count || 0);
      return totals;
    },
    {
      totalCount: 0,
      totalPaypal: 0,
      totalStripe: 0,
    }
  );

  function getLabel(stat: Stat): string | undefined {
    switch (group) {
      case DonationGroup.WELL_TYPE:
        return `${stat.country} - ${
          stat.well_pump && WELL_PUMPS[stat.well_pump]
        }`;
      case DonationGroup.COUNTRY:
        return stat.user_country;
      case DonationGroup.PROJECT:
      default:
        return stat.charity_organization;
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell className={classes.head}>Number</TableCell>
              <TableCell className={classes.head}>Paypal</TableCell>
              <TableCell className={classes.head}>Stripe</TableCell>
              <TableCell className={classes.head}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map((stat: Stat, index: number) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {getLabel(stat)}
                </TableCell>
                <TableCell className={classes.number}>
                  {(stat.STRIPE?.count || 0) + (stat.PAYPAL?.count || 0)}
                </TableCell>
                <TableCell className={classes.number}>
                  {stat.PAYPAL?.amount || 0}
                </TableCell>
                <TableCell className={classes.number}>
                  {stat.STRIPE?.amount || 0}
                </TableCell>
                <TableCell className={classes.number}>
                  {(stat.STRIPE?.amount || 0) + (stat.PAYPAL?.amount || 0)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell className={classes.number}>{totalCount}</TableCell>
              <TableCell className={classes.number}>{totalPaypal}</TableCell>
              <TableCell className={classes.number}>{totalStripe}</TableCell>
              <TableCell className={classes.number}>
                {totalPaypal + totalStripe}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
