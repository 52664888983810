import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { BuildYourOwnWellMetaData, Donation } from "../core";
import { useTotals } from "../hooks/useTotals";
import WELL_PUMPS from "../core/data/well-pumps.json";

const useStyles = makeStyles({
  head: {
    fontWeight: "bold",
    textAlign: "right",
  },
  number: {
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
});

export function StatsBuildYourOwnWell({
  donations,
}: {
  donations: Donation<BuildYourOwnWellMetaData>[];
}) {
  const classes = useStyles();
  const { totalPaypal, totalStripe } = useTotals(donations);
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Donator</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Name on the well</TableCell>
              <TableCell className={classes.head}>Paypal</TableCell>
              <TableCell className={classes.head}>Stripe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map(
              (donation: Donation<BuildYourOwnWellMetaData>, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    {donation.first_name} {donation.last_name}
                  </TableCell>
                  <TableCell>
                    {new Date(donation.created_at).toLocaleDateString()}&nbsp;
                    {new Date(donation.created_at).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>
                    {donation.metadata?.country} - {WELL_PUMPS[donation.metadata?.well_pump]}
                  </TableCell>
                  <TableCell>{donation.email}</TableCell>
                  <TableCell>{donation.metadata.user_country}</TableCell>
                  <TableCell>{donation.language}</TableCell>
                  <TableCell>{donation.metadata?.name_on_well}</TableCell>
                  <TableCell className={classes.number}>
                    {donation.payment_gateway === "PAYPAL"
                      ? donation.amount
                      : 0}
                  </TableCell>
                  <TableCell className={classes.number}>
                    {donation.payment_gateway === "STRIPE"
                      ? donation.amount
                      : 0}
                  </TableCell>
                </TableRow>
              )
            )}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={classes.number}>{totalPaypal}</TableCell>
              <TableCell className={classes.number}>{totalStripe}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
