import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import * as React from "react";
import { Title } from "react-admin";
import { useQuery } from "react-query";
import { DateRange, useDateRange } from "../components/DateRange";
import { StatsGlobal } from "../containers/StatsGlobal";
import { StatsSubscriptions } from "../containers/StatsSubscriptions";
import {
  getSubscriptions,
  SubscriptionsResponse,
  SubscriptionStatus,
} from "../core/api";
import { firstDayOfMonth } from "../helpers/Date";
const today = new Date();
const firstDay = firstDayOfMonth();

export function Subscriptions() {
  const [status, setStatus] = React.useState<SubscriptionStatus>(
    SubscriptionStatus.ENABLED
  );
  // const [group, setGroup] = React.useState<DonationGroup>(
  //   DonationGroup.COUNTRY
  // );
  const { fromDate, toDate, onDateRageChange } = useDateRange(firstDay, today);
  const { data } = useQuery<SubscriptionsResponse, Error>(
    ["subscriptions", { status, fromDate, toDate }],
    (key, { status, fromDate, toDate }) =>
      getSubscriptions(status, fromDate, toDate)
  );

  return (
    <div>
      <Title title="Subscriptions" />
      <FormGroup row>
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={onDateRageChange}
        />
        <RadioGroup row>
          <FormControlLabel
            checked={status === SubscriptionStatus.ENABLED}
            control={<Radio color="primary" />}
            label="Subscriptions"
            labelPlacement="start"
            onChange={(e: any) => setStatus(SubscriptionStatus.ENABLED)}
          />
          <FormControlLabel
            checked={status === SubscriptionStatus.CANCELED}
            control={<Radio color="primary" />}
            label="Cancellations"
            labelPlacement="start"
            onChange={(e: any) => setStatus(SubscriptionStatus.CANCELED)}
          />
        </RadioGroup>
      </FormGroup>
      <h1> Global Stats </h1>
      <StatsGlobal stats={data?.stats || []} />
      <h1> Detailed Stats </h1>
      <StatsSubscriptions subscriptions={data?.subscriptions || []} />
    </div>
  );
}
