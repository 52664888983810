import * as React from "react";
import { ReactElement } from "react";

export interface QueryResponseProps {
  loading: boolean;
  error: Error | null;
  children: ReactElement;
}

export function QueryResponse({
  loading,
  error,
  children,
}: QueryResponseProps): ReactElement {
  if (loading) return <div>Loading...</div>;

  if (error)
    return (
      <div>
        <span>An error has occurred: </span> {error}
      </div>
    );
  return children;
}
