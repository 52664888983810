import { authProvider } from "../auth/authProvider";
import { Stat, Subscription } from "../models";

export interface SubscriptionsResponse {
  subscriptions: Subscription[];
  stats: Stat[];
}

export enum SubscriptionStatus {
  ENABLED = "ENABLED",
  CANCELED = "CANCELED",
}

const apiUrl = `${process.env.REACT_APP_BASE_URL}/dashboard/subscriptions`;

export async function getSubscriptions(
  status: SubscriptionStatus,
  fromDate?: string,
  toDate?: string
): Promise<SubscriptionsResponse> {
  let dateFilters = "";
  dateFilters += fromDate ? `subscribe_from_date=${fromDate}` : "";
  dateFilters += toDate ? `&subscribe_to_date=${toDate}` : "";
  const statusFilter = `subscription_status=${status}`;
  return await authProvider.fetch(
    `${apiUrl}?group_by=charity_organization&format=AMOUNT&subscription_status=${status}&${dateFilters}&${statusFilter}`
  );
}
