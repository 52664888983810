import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { Subscription } from "../core";
import { useTotals } from "../hooks/useTotals";

const useStyles = makeStyles({
  head: {
    fontWeight: "bold",
    textAlign: "right",
  },
  number: {
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
});

export function StatsSubscriptions({ subscriptions }: { subscriptions: Subscription[] }) {
  const classes = useStyles();
  const { totalPaypal, totalStripe } = useTotals(subscriptions);
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Donator</TableCell>
              <TableCell>Country</TableCell>
              <TableCell className={classes.head}>Paypal</TableCell>
              <TableCell className={classes.head}>Stripe</TableCell>
              <TableCell className={classes.head}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((donation: Subscription, index: number) => (
              <TableRow key={index}>
                <TableCell>
                  {new Date(donation.created_at).toLocaleDateString()}&nbsp;
                  {new Date(donation.created_at).toLocaleTimeString()}
                </TableCell>
                <TableCell>{donation.charity_organization}</TableCell>
                <TableCell>
                  {donation.first_name} {donation.last_name}
                </TableCell>
                <TableCell>{donation.metadata?.user_country}</TableCell>
                <TableCell className={classes.number}>
                  {donation.payment_gateway === "PAYPAL" ? donation.amount : 0}
                </TableCell>
                <TableCell className={classes.number}>
                  {donation.payment_gateway === "STRIPE" ? donation.amount : 0}
                </TableCell>
                <TableCell className={classes.number}>
                  {donation.amount}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={classes.number}>{totalPaypal}</TableCell>
              <TableCell className={classes.number}>{totalStripe}</TableCell>
              <TableCell className={classes.number}>
                {totalPaypal + totalStripe}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
