import * as React from "react";
import jsonServerProvider from "ra-data-json-server";
import { Admin, ListGuesser, Resource } from "react-admin";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { authProvider } from "./core/auth/authProvider";
import customRoutes from "./customRoutes";
import Layout from "./Layout/Layout";
import { LoginPage } from "./Layout/LoginPage";
import { Dashboard } from "./pages/Dashboard";
const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

const queryCache = new QueryCache();

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Admin
        loginPage={LoginPage}
        customRoutes={customRoutes}
        layout={Layout}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
      >
        <Resource name="users" list={ListGuesser} />
      </Admin>
    </ReactQueryCacheProvider>
  );
}

export default App;
