import { login, requestLogin } from "../api/auth";

export const authProvider = {
  async fetch(url: any) {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      switch (response.status) {
        case 401:
        case 403:
        case 422:
          authProvider.logout();
          break;
        default:
          return data;
      }
    }
  },
  login: async ({ email, password }: { email: string; password: string }) => {
    try {
      if (!password) {
        await requestLogin(email);
        return Promise.reject();
      }
      const jwt = await login(email, password);
      localStorage.setItem("token", jwt);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error || "Unexpected error");
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: (params: any) => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error: any) => Promise.resolve(),
  getPermissions: (params: any) => Promise.resolve(),
};
