import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { Title } from "react-admin";
import { QueryResponse } from "../components/QueryResponse";
import { SubscriptionStatus } from "../core/api";
import { useSubscriptions } from "../core/hooks/useSubscriptions";
import { Stat, Subscription } from "../core/models";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontWeight: "bold",
  },
  text: {
    fontSize: 18,
    marginBottom: 5,
    textAlign: "center",
  },
  total: {
    padding: theme.spacing(6),
    textAlign: "center",
    fontSize: 48,
    color: theme.palette.text.secondary,
  },
  detail: {
    padding: theme.spacing(2),
    textAlign: "center",
    fontSize: 24,
  },
  head: {
    fontWeight: "bold",
  },
}));

export function Dashboard() {
  const classes = useStyles();
  const { isLoading, error, data } = useSubscriptions(
    SubscriptionStatus.ENABLED
  );
  return (
    <QueryResponse loading={isLoading} error={error}>
      <div className={classes.root}>
        <Title title="Dashboard" />
        <Grid container spacing={3}>
          {data?.stats?.map((stat: Stat, index: number) => (
            <Grid key={index} item xs>
              <div className={classes.text}>{stat.charity_organization}</div>
              <Paper>
                <div className={classes.total}>
                  {(stat.PAYPAL?.amount || 0) + (stat.STRIPE?.amount || 0)}$
                </div>
                <div className={classes.detail}>
                  <span>Stripe: {stat.STRIPE?.amount || 0} $</span>
                </div>
                <div className={classes.detail}>
                  <span>Paypal: {stat.PAYPAL?.amount || 0} $</span>
                </div>
              </Paper>
            </Grid>
          ))}
          <Grid key={(data?.stats.length || 0) + 1} item xs>
            <div className={classes.text}>Active Subscribers</div>
            <Paper>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.head}>Donator</TableCell>
                      <TableCell className={classes.head}>Date</TableCell>
                      <TableCell className={classes.head}>Email</TableCell>
                      <TableCell className={classes.head}>Country</TableCell>
                      <TableCell className={classes.head}>Language</TableCell>
                      <TableCell className={classes.head}>Project</TableCell>
                      <TableCell className={classes.head}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.subscriptions.map(
                      (subscription: Subscription, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            {subscription.first_name} {subscription.last_name}
                          </TableCell>
                          <TableCell>
                            {new Date(subscription.created_at).toLocaleDateString()}
                            &nbsp;
                            {new Date(subscription.created_at).toLocaleTimeString()}
                          </TableCell>
                          <TableCell>{subscription.email}</TableCell>
                          <TableCell>
                            {subscription.metadata.user_country}
                          </TableCell>
                          <TableCell>{subscription.language}</TableCell>
                          <TableCell>{subscription.charity_organization}</TableCell>
                          <TableCell>{subscription.amount}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </QueryResponse>
  );
}
